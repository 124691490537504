import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <main>
        <div
          className="wrapper-body"
          style={{ backgroundColor: '#fff' }}
          id="why-use-e1"
        >
          <section
            id="intro"
            style={{ height: '450px', backgroundImage: `url(${HeroImage})` }}
          >
            <div className="container">
              <div
                className="col-md-12"
                style={{ paddingTop: '120px', paddingBottom: '100px' }}
              >
                <h1>{frontmatter.title}</h1>
              </div>
            </div>
          </section>

          <div className="container">
            <div className="col-md-12">
              <div className="padding-topbottom-50 main-text">
                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />

                <span
                  className="margin-topbottom-20"
                  style={{ fontSize: '15px', float: 'left' }}
                >
                  Published on: {frontmatter.date}
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
